import { useMemo } from 'react';
import { CalendarIcon } from 'assets/img';
import { DropdownArray } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getAnalyticsAuthentication,
  getAnalyticsAuthenticationPage,
  getAnalyticsRdpAttempts,
  getAnalyticsRdpAttemptsPage,
  updateSelectedRange,
  updateSelectedUsers,
} from 'store/slices/analytics';
import { prepareTimeRangeConfiguration } from 'utils';
import DropdownCheckUsers from 'pages/Analytics/components/DropdownCheckUsers/DropdownCheckUsers';
import styles from './AuthenticationFilter.module.scss';
import Skeleton from 'react-loading-skeleton';

interface Props {
  domainId: string;
  home?: boolean;
  rdp?: boolean;
  showTitle?: boolean;
}

const AuthenticationFilter = ({ domainId, home, rdp = false, showTitle }: Props) => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.analytics.getAnalyticsAuthenticationRequest);
  const { users, selectedUsers, selectedRange } = useAppSelector((state) => state.analytics);

  const timeRange = useMemo(() => prepareTimeRangeConfiguration(), []);

  const getOptions = (range: string = selectedRange, thisUsers: string[] = selectedUsers) => {
    const rangeData = timeRange.find((i) => i.text.toString() === range);
    if (!rangeData) {
      throw new Error('Invalid range');
    }

    let userIds: string[] | null = thisUsers;
    if (thisUsers.length === 0) {
      userIds = null;
    } else if (users.length === selectedUsers.length) {
      userIds = [];
    }

    return {
      start: rangeData.startDate,
      end: rangeData.endDate,
      userIds,
    };
  };

  const handleUpdateDateRange = async (range: string) => {
    dispatch(updateSelectedRange(range));
    const options = getOptions(range, selectedUsers);
    const payload = { domainId, ...options };
    const query = { pageNumber: 1 };
    if (home) {
      await Promise.all([
        dispatch(getAnalyticsRdpAttempts({ payload, query })).unwrap(),
        dispatch(getAnalyticsAuthentication({ payload, query })).unwrap(),
      ]);
      return;
    }
    await Promise.all([
      rdp
        ? dispatch(getAnalyticsRdpAttempts({ payload, query })).unwrap()
        : dispatch(getAnalyticsAuthentication({ payload, query })).unwrap(),
      rdp
        ? dispatch(getAnalyticsRdpAttemptsPage({ payload, query })).unwrap()
        : dispatch(getAnalyticsAuthenticationPage({ payload, query })).unwrap(),
    ]);
  };

  const handleUpdateUsers = async (users: string[]) => {
    dispatch(updateSelectedUsers(users));
    const options = getOptions(selectedRange, users);
    const payload = { domainId, ...options };
    const query = { pageNumber: 1 };
    if (home) {
      await Promise.all([
        dispatch(getAnalyticsRdpAttempts({ payload, query })).unwrap(),
        dispatch(getAnalyticsAuthentication({ payload, query })).unwrap(),
      ]);
      return;
    }
    await Promise.all([
      rdp
        ? dispatch(getAnalyticsRdpAttempts({ payload, query })).unwrap()
        : dispatch(getAnalyticsAuthentication({ payload, query })).unwrap(),
      rdp
        ? dispatch(getAnalyticsRdpAttemptsPage({ payload, query })).unwrap()
        : dispatch(getAnalyticsAuthenticationPage({ payload, query })).unwrap(),
    ]);
  };

  return (
    <div className={styles.authentication}>
      {showTitle && <h4 className={styles.authenticationTitle}>Authentication</h4>}
      <DropdownArray
        id="reports-select-range"
        isSmall={false}
        isSearch={false}
        isFilter
        icon={<CalendarIcon />}
        options={timeRange.map((i) => i.text.toString())}
        defaultValue={selectedRange}
        onChange={(value) => handleUpdateDateRange(value as string)}
        width="12em"
      />
      <DropdownCheckUsers
        id="reports-select-users"
        options={users}
        optionsChecked={selectedUsers}
        valueKey="upn"
        checkKey="userId"
        disabled={users.length === 0}
        defaultValue={<>All Users {isLoading ? <Skeleton width={30} /> : <b>({selectedUsers.length})</b>}</>}
        onToggleCheck={handleUpdateUsers}
      />
    </div>
  );
};

export default AuthenticationFilter;
