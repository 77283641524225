import { hideCrispChat } from 'helpers';
import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import LiveChat from '../LiveChat/LiveChat';
import { useLocation } from 'react-router-dom';
import FreeTrialEndedModal from './FreeTrialEndedModal/FreeTrialEndedModal';
import { useAppSelector } from 'store';
import styles from './Layout.module.scss';

interface Props {
  children: JSX.Element;
}

const Layout = ({ children }: Props) => {
  const { user, branding } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();
  const { firstPaymentDone, domainWasAutovalidated } = user;
  const isProfilePages = pathname.includes('/profile');

  const showFreeTrialEndedModal = !firstPaymentDone && !isProfilePages;

  return (
    <>
      {domainWasAutovalidated && (
        <>
          {showFreeTrialEndedModal && <FreeTrialEndedModal user={user} />}
          {showFreeTrialEndedModal && <div className={styles.layoutOverlay} />}
        </>
      )}
      <div className={styles.layoutContainer}>
        <Navbar />
        <div className={styles.layoutContent}>
          <Header />
          <div id="layout" className={styles.layoutWrapper}>
            {children}
          </div>
        </div>
      </div>
      {hideCrispChat && <LiveChat user={user} branding={branding} />}
    </>
  );
};

export default Layout;
