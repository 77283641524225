import { memo, useState } from 'react';
import cn from 'classnames';
import { ArrowLeftIcon } from 'assets/img';
import Button from 'components/Button/Button';
import RowNumbers from './components/RowNumbers/RowNumbers';
import PageNumbers from './components/PageNumbers/PageNumbers';
import { IPageMeta, IQueryConfig } from 'interfaces';
import { notify } from 'utils';
import styles from './Pagination.module.scss';

interface Props {
  pageMeta: IPageMeta;
  queryConfig?: IQueryConfig | null;
  paginationAction: (query: IQueryConfig) => Promise<void>;
}

const Pagination = ({ pageMeta, queryConfig, paginationAction }: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onPageChangeHandler = async (page: number, pageSize: number) => {
    try {
      setIsDisabled(true);
      const query: IQueryConfig = { ...queryConfig, pageNumber: page, pageSize: pageSize };
      await paginationAction(query);
      setIsDisabled(false);
    } catch (err: any) {
      notify.error(err.message);
      setIsDisabled(false);
    }
  };

  const isNextPage = pageMeta.TotalPages > pageMeta.CurrentPage;
  const isPrevPage = pageMeta.CurrentPage > 1;

  return (
    <div className={cn(styles.pagination, { [styles.paginationLoad]: isDisabled })}>
      <div className={styles.paginationEmpty} />
      <div className={styles.paginationPages}>
        <Button
          variant="link"
          icon={<ArrowLeftIcon />}
          className={cn(styles.paginationFlip, { [styles.paginationFlipActive]: isPrevPage })}
          onClick={() => onPageChangeHandler(pageMeta.CurrentPage - 1, pageMeta.PageSize)}>
          Prev
        </Button>
        <PageNumbers
          totalPages={pageMeta.TotalPages}
          currentPage={pageMeta.CurrentPage}
          pageSize={pageMeta.PageSize}
          onPageChangeHandler={onPageChangeHandler}
        />
        <Button
          variant="link"
          icon={<ArrowLeftIcon />}
          className={cn(styles.paginationFlip, styles.paginationFlipNext, {
            [styles.paginationFlipActive]: isNextPage,
          })}
          onClick={() => onPageChangeHandler(pageMeta.CurrentPage + 1, pageMeta.PageSize)}>
          Next
        </Button>
      </div>
      <RowNumbers pageSize={pageMeta.PageSize} onPageChangeHandler={onPageChangeHandler} />
    </div>
  );
};

Pagination.displayName = 'Pagination';

export default memo(Pagination);
