import { Table } from 'components';
import { Column } from 'components/Table/Table';
import { useAppDispatch, useAppSelector } from 'store';
import { IAnalyticsComputer } from 'interfaces/analytics';
import { AzureJoinedMashineIcon, GroupMachineIcon, SecureConnectIcon, SingleMachineIcon } from 'assets/img';
import { IQueryConfig } from 'interfaces';
import { getAnalyticsComputersDetailed } from 'store/slices/analytics';
import styles from './TotalComputersTable.module.scss';

interface Props {
  isDomainAzure?: boolean;
}

const TotalComputersTable = ({ isDomainAzure = false }: Props) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsComputersDetailedRequest);

  const columns: Column[] = [
    {
      name: 'Computer Name',
      width: '40%',
      sortable: 'name',
      data: (item: IAnalyticsComputer) => {
        const itemIcon =
          item.type === 'sentry' && item.groups.length < 1 ? (
            <SingleMachineIcon />
          ) : item.type === 'secureConnect' && !item.isAzureJoined ? (
            <SecureConnectIcon />
          ) : item.isAzureJoined ? (
            <AzureJoinedMashineIcon />
          ) : (
            <GroupMachineIcon />
          );
        return (
          <div className={styles.tableName}>
            {itemIcon}
            <div className="truncate-text">{item.name}</div>
          </div>
        );
      },
    },
    {
      name: 'Computer Group',
      width: '30%',
      data: (item: IAnalyticsComputer) => {
        if (item.groups.length > 0) {
          return (
            <div className={styles.tableGroup}>
              {item.groups.map((group) => (
                <span key={group}>{group}</span>
              ))}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
    {
      name: 'OS Type',
      width: '30%',
      data: 'osType',
    },
  ];

  if (isDomainAzure) {
    columns.splice(1, 1);
  }

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getAnalyticsComputersDetailed({ query, _background: true }));
  };

  return (
    <Table<IAnalyticsComputer>
      id="computers-detailed-table"
      className={styles.table}
      classNameRow={(item: IAnalyticsComputer) => (item.groups.length > 1 ? styles.tableRowBaseline : '')}
      dataKey="machineId"
      columns={columns}
      isLoading={isLoading}
      data={{
        data: data?.data,
        queryConfig: data?.queryConfig,
        pageMeta: data?.pageMeta,
      }}
      dispatchAction={dispatchAction}
    />
  );
};

export default TotalComputersTable;
