import { IDomain } from 'interfaces';
import TotalInfo from './components/TotalInfo/TotalInfo';
import AuthenticationFilter from './components/AuthenticationFilter/AuthenticationFilter';
import TotalLogins from './components/TotalLogins/TotalLogins';
import TotalRDP from './components/TotalRDP/TotalRDP';
import styles from './Users.module.scss';

interface Props {
  domain: IDomain;
}

const Users = ({ domain }: Props) => (
  <div className={styles.users}>
    <div className={styles.usersTotalInfo}>
      <TotalInfo domain={domain} />
    </div>
    <div className={styles.usersAuthenticationFilter}>
      <AuthenticationFilter domainId={domain.id} home showTitle />
    </div>
    <div className={styles.usersRow}>
      <div className={styles.usersCol}>
        <TotalLogins />
      </div>
      <div className={styles.usersCol}>
        <TotalRDP />
      </div>
    </div>
  </div>
);

export default Users;
