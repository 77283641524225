import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, SearchInput } from 'components';
import { AngleRightIcon } from 'assets/img';
import StatsInfo from './components/StatsInfo/StatsInfo';
import { IDomain, IQueryConfig } from 'interfaces';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { getAnalyticsComputers, getAnalyticsComputersGroupsDetailed } from 'store/slices/analytics';
import { replaceSpecChar } from 'helpers';
import ComputerGroupsTable from './components/ComputerGroupsTable/ComputerGroupsTable';
import styles from './ComputerGroups.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}

const ComputerGroups = ({ domain, isCompanyPage }: Props) => {
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsComputersGroupsDetailedRequest);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const goBack = () => {
    if (isCompanyPage) {
      return history.replace('/company-management/analytics?tab=computers');
    } else {
      return history.push(`/customer-management/${domain.dnsName}/analytics?tab=computers`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryConfig: IQueryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'name asc',
          searchQuery: '',
          domainId: domain.id,
        };
        await Promise.all([
          dispatch(getAnalyticsComputers({ domainId: domain.id, _background: !!data })).unwrap(),
          dispatch(getAnalyticsComputersGroupsDetailed({ query: queryConfig })).unwrap(),
        ]);
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchData();
  }, []);

  const onSearch = async (value: string) => {
    const valueNew = replaceSpecChar(value);
    const queryConfig = {
      pageNumber: 1,
      searchQuery: valueNew,
    };
    await dispatch(getAnalyticsComputersGroupsDetailed({ query: queryConfig })).unwrap();
  };

  return (
    <div className={styles.computer}>
      <div className={styles.computerTitleWrap}>
        <Button icon={<AngleRightIcon />} variant="link" onClick={goBack} className={styles.computerTitleIcon} />
        <h1 className={styles.computerTitle}>Computer Groups</h1>
      </div>
      <StatsInfo />
      <div className={styles.computerSearch}>
        <SearchInput
          isLoading={isLoading}
          id="analytics-computer-search"
          onChange={onSearch}
          searchBy="Computer Group"
          inputClassName={styles.computerSearchInput}
        />
      </div>
      <ComputerGroupsTable />
    </div>
  );
};

export default ComputerGroups;
