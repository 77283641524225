import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useAppSelector } from 'store';
import { analyticsChartConfig, analyticsChartOptions } from 'utils';

interface Stats {
  successful: { x: any; y: number }[];
  failed: { x: any; y: number }[];
}
Chart.register({
  id: 'NoData',
  afterDraw: function (chart) {
    const data = chart.data.datasets.map((dataset) =>
      dataset.data.map((point) => {
        if (typeof point === 'object' && point !== null && 'y' in point) {
          return (point as { y: number }).y;
        }
        return point;
      }),
    );

    const isEmpty = data.every((dataset) => dataset.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) === 0);

    if (isEmpty) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = `1em IBM Plex Sans`;
      ctx.fillStyle = '#848A92';
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  },
});
const LoginGraphic = () => {
  const { data: logins } = useAppSelector((state) => state.analytics.getAnalyticsAuthenticationRequest);

  const stats: Stats = { successful: [], failed: [] };
  if (logins?.data.dailyStats) {
    stats.successful = logins.data.dailyStats.map((item) => ({
      x: new Date(item.day),
      y: item.successfull,
    }));
    stats.failed = logins.data.dailyStats.map((item) => ({
      x: new Date(item.day),
      y: item.failed,
    }));
  }

  const data = analyticsChartConfig(stats.successful, stats.failed);
  const options = analyticsChartOptions(stats.successful);
  options.maintainAspectRatio = false;

  return <Line data={data} options={options} height="100%" />;
};

export default LoginGraphic;
