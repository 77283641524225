const getConfigurationForThisYear = () => {
  const thisYear: number = new Date().getUTCFullYear();
  const startRange: Date = new Date(Date.UTC(thisYear, 0, 1));
  const endRange: Date = new Date();

  return {
    text: thisYear,
    value: 'this_year',
    startDate: startRange,
    endDate: endRange,
  };
};

const getConfigurationForLastYear = () => {
  const lastYear: number = new Date().getUTCFullYear() - 1;
  const startRange: Date = new Date(Date.UTC(lastYear, 0, 1));
  const endRange: Date = new Date(Date.UTC(lastYear, 11, 31));

  return {
    text: lastYear,
    value: 'last_year',
    startDate: startRange,
    endDate: endRange,
  };
};

const getConfigurationForThisMonth = () => {
  const now = new Date();
  const thisYear: number = now.getUTCFullYear();
  const thisMonth: number = now.getUTCMonth();
  const startRange: Date = new Date(Date.UTC(thisYear, thisMonth, 1));
  const endRange: Date = new Date();

  const monthName: string = new Intl.DateTimeFormat('en-US', { month: 'long', timeZone: 'UTC' }).format(
    new Date(Date.UTC(thisYear, thisMonth, 1)),
  );

  return {
    text: monthName,
    value: 'this_month',
    startDate: startRange,
    endDate: endRange,
  };
};

const getConfigurationForLastMonth = () => {
  const now = new Date();
  const thisYear: number = now.getUTCFullYear();
  const thisMonth: number = now.getUTCMonth();
  let lastMonth: number = thisMonth - 1;
  let year: number = thisYear;

  if (lastMonth < 0) {
    lastMonth = 11;
    year--;
  }

  const startRange: Date = new Date(Date.UTC(year, lastMonth, 1));
  const endRange: Date = new Date(Date.UTC(thisYear, thisMonth, 1) - 1);
  const monthName: string = new Intl.DateTimeFormat('en-US', { month: 'long', timeZone: 'UTC' }).format(
    new Date(Date.UTC(year, lastMonth, 1)),
  );

  return {
    text: monthName,
    value: 'last_month',
    startDate: startRange,
    endDate: endRange,
  };
};

interface ITimeRangeConfiguration {
  text: string | number;
  value: string;
  startDate: Date;
  endDate: Date;
}

export const prepareTimeRangeConfiguration = (): ITimeRangeConfiguration[] => {
  const last7Days = {
    text: 'Last 7 days',
    value: '7_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
  };

  const last14Days = {
    text: 'Last 14 days',
    value: '14_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
  };

  const last30Days = {
    text: 'Last 30 days',
    value: '30_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
  };

  const last90Days = {
    text: 'Last 90 days',
    value: '90_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
  };

  const last365Days = {
    text: 'Last 365 days',
    value: '365_days',
    endDate: new Date(new Date().getTime()),
    startDate: new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
  };

  const thisYear = getConfigurationForThisYear();
  const lastYear = getConfigurationForLastYear();
  const thisMonth = getConfigurationForThisMonth();
  const pastMonth = getConfigurationForLastMonth();

  return [last7Days, last14Days, last30Days, last90Days, last365Days, thisYear, lastYear, thisMonth, pastMonth];
};
