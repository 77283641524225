import { forwardRef, useState, ChangeEvent } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { SearchIcon } from 'assets/img';
import styles from './SearchInput.module.scss';

interface Props {
  id: string;
  className?: string;
  isLoading?: boolean;
  inputClassName?: string;
  fullWidth?: boolean;
  strightLeft?: boolean;
  searchBy?: string;
  isNotDebounce?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
}

const SearchInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      className,
      isLoading,
      inputClassName,
      fullWidth,
      strightLeft,
      searchBy,
      isNotDebounce,
      onChange,
      placeholder,
    },
    ref,
  ) => {
    const [inputValue, setValue] = useState<string>('');

    const onSearchDebounced = useDebouncedCallback((value: string) => {
      onChange(value);
      setValue(value);
    }, 1000);

    const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (isNotDebounce) {
        setValue(value);
        onChange(value);
      } else {
        setValue(value);
        onSearchDebounced(value);
      }
    };

    return (
      <div className={cn(styles.search, { [className!]: className })}>
        <input
          type="search"
          value={inputValue}
          id={id}
          ref={ref}
          className={cn(styles.searchInput, 'input-ad-search', {
            [styles.searchInputFullWidth]: fullWidth,
            [styles.searchInputStrightLeft]: strightLeft,
            [styles.isLoading]: isLoading,
            [inputClassName!]: inputClassName,
            isLoading: isLoading,
          })}
          placeholder={searchBy ? `Search by ${searchBy}` : placeholder}
          onChange={handleChangeValue}
        />
        <SearchIcon className={styles.searchIcon} />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
