import { Link } from 'components';
import LoginGraphic from './components/LoginGraphic/LoginGraphic';
import StatsInfo from './components/StatsInfo/StatsInfo';
import styles from './TotalLogins.module.scss';
import { useRouteMatch } from 'react-router-dom';

const TotalLogins = () => {
  const { url } = useRouteMatch();

  return (
    <div className={styles.container}>
      <StatsInfo />
      <div className={styles.containerGraphic}>
        <LoginGraphic />
      </div>
      <div className={styles.link}>
        <Link to={`${url}/logins`}>View details</Link>
      </div>
    </div>
  );
};

export default TotalLogins;
