import { SearchInput } from 'components';
import styles from './EntraIDTable.module.scss';
import TotalComputersTable from 'pages/Analytics/pages/TotalComputers/components/TotalComputersTable/TotalComputersTable';
import { notify } from 'utils';
import { replaceSpecChar } from 'helpers';
import { getAnalyticsComputersDetailed } from 'store/slices/analytics';
import { useEffect } from 'react';
import { IDomain, IQueryConfig } from 'interfaces';
import { useAppDispatch } from 'store';

interface Props {
  domain: IDomain;
  isDomainAzure: boolean;
}

const EntraIDTable = ({ domain, isDomainAzure }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryConfig: IQueryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'name asc',
          searchQuery: '',
          domainId: domain.id,
        };
        await dispatch(getAnalyticsComputersDetailed({ query: queryConfig })).unwrap();
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchData();
  }, []);

  const onSearch = async (value: string) => {
    const valueNew = replaceSpecChar(value);
    const queryConfig = {
      pageNumber: 1,
      searchQuery: valueNew,
    };
    await dispatch(getAnalyticsComputersDetailed({ query: queryConfig })).unwrap();
  };

  return (
    <div className={styles.logins}>
      <div className={styles.loginsSearch}>
        <SearchInput id="analytics-logins-search" onChange={onSearch} searchBy="Computer Name" />
      </div>
      <TotalComputersTable isDomainAzure={isDomainAzure} />
    </div>
  );
};

export default EntraIDTable;
