import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { IDomain } from 'interfaces';
import TotalLogins from './pages/TotalLogins/TotalLogins';
import TotalRDP from './pages/TotalRDP/TotalRDP';
import TotalComputers from './pages/TotalComputers/TotalComputers';
import ComputerGroups from './pages/ComputerGroups/ComputerGroups';
import LicensingDetails from './pages/LicensingDetails/LicensingDetails';
import AnalyticsTabs from './components/AnalyticsTabs/AnalyticsTabs';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
}

const Analytics = ({ domain, userRole, isCompanyPage }: Props) => {
  const { url } = useRouteMatch();

  return (
    <SkeletonTheme baseColor="#EDF1F6" highlightColor="#F8F9FB">
      <Switch>
        <Route path={url} exact>
          <AnalyticsTabs domain={domain} userRole={userRole} isCompanyPage={isCompanyPage} />
        </Route>
        <Route path={`${url}/logins`}>
          <TotalLogins domain={domain} isCompanyPage={isCompanyPage} />
        </Route>
        <Route path={`${url}/rdp`}>
          <TotalRDP domain={domain} isCompanyPage={isCompanyPage} />
        </Route>
        <Route path={`${url}/computers`}>
          <TotalComputers domain={domain} isCompanyPage={isCompanyPage} />
        </Route>
        <Route path={`${url}/computer-groups`}>
          <ComputerGroups domain={domain} isCompanyPage={isCompanyPage} />
        </Route>
        <Route path={`${url}/licensing`}>
          <LicensingDetails
            primaryDomain={domain.dnsName}
            userRole={userRole}
            isCompanyPage={isCompanyPage}
            domain={domain}
          />
        </Route>
      </Switch>
    </SkeletonTheme>
  );
};

export default Analytics;
