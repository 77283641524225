import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styles from './LicenseAllocationChart.module.scss';
import { useAppSelector } from 'store';

interface Props {
  selectedLicenseType: string;
}

const LicenseAllocationChart = ({ selectedLicenseType }: Props) => {
  const { data } = useAppSelector((state) => state.analytics.getAnalyticsLicensesRequest);

  const purchasedLabels =
    data?.purchasedLicenses?.filter((domain) => domain.count > 0).map((domain) => domain.domainName) || [];

  const labels =
    data?.allocatedLicenses
      .filter((i) => !(i.licensesAllocated === 0 && i.reportedItems === 0))
      .map((i) => i.domainName) || [];
  const usersReporting =
    data?.allocatedLicenses
      .filter((i) => !(i.licensesAllocated === 0 && i.reportedItems === 0))
      .map((i) => (i.reportedItems ? i.reportedItems : 0.00001)) || [];
  const licenses =
    data?.allocatedLicenses
      .filter((i) => !(i.licensesAllocated === 0 && i.reportedItems === 0))
      .map((i) => -i.licensesAllocated) || [];
  const differences =
    data?.allocatedLicenses
      .filter((i) => !(i.licensesAllocated === 0 && i.reportedItems === 0))
      .map((i) => i.difference) || [];

  const maxDataValue = data ? Math.max(...usersReporting, ...licenses) : 0;
  const minDataValue = data ? Math.min(...usersReporting, ...licenses) : 0;

  const minMaxValue = Math.max(Math.abs(minDataValue), Math.abs(maxDataValue));

  const chartData: ChartData<'bar'> = {
    labels: labels,
    datasets: [
      {
        label: 'Licenses Allocated',
        data: licenses,
        backgroundColor: '#61B8FF',
        borderColor: '#61B8FF',
        borderWidth: 0,
        maxBarThickness: 12,
        datalabels: {
          display: false,
        },
      },
      {
        label: selectedLicenseType === 'SecureRDP' ? 'Users Reporting in' : 'Installed IoT Agents',
        data: usersReporting,
        backgroundColor: '#3673E8',
        borderColor: '#3673E8',
        borderWidth: 0,
        maxBarThickness: 12,
        datalabels: {
          align: 'end',
          anchor: 'end',
          color: '#000000',
          font: {
            family: '"IBM Plex Sans", sans-serif',
            weight: 500,
            size: 10,
          },
        },
      },
    ],
  };

  const chartOptions: ChartOptions<'bar'> = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    layout: {
      padding: {
        right: 42,
        left: 24,
      },
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            formatter: (value, context) => {
              const diff = differences[context.dataIndex];
              return diff > 0 ? `+${diff}` : diff;
            },
            color: (context) => {
              const diff = differences[context.dataIndex];
              return diff > 0 ? '#0FC015D9' : diff === 0 ? '#848A92' : '#FF2020D9';
            },
          },
        },
      },
      legend: {
        labels: {
          padding: 20,
          font: {
            family: '"IBM Plex Sans", sans-serif',
            size: 12,
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        titleFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
          weight: '600',
        },
        bodyFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
        },
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => ({
            pointStyle: 'circle',
            rotation: 0,
          }),
          label: (context) => {
            const label = context.dataset.label || '';
            const value = (context.raw as number) === 0.00001 ? 0 : context.raw;
            return Math.sign(Number(value)) ? ` ${label}: ${String(value).replace('-', '')}` : ` ${label}: ${value} `;
          },
        },
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [1.5, 1.5],
        },
        ticks: {
          callback: function (value: string | number) {
            return Math.sign(Number(value)) ? String(value).replace('-', '') : value;
          },
        },
        min: Number.isFinite(minMaxValue) ? -minMaxValue - 5 : 0,
        max: Number.isFinite(minMaxValue) ? minMaxValue + 5 : 0,
      },
      y: {
        stacked: true,
        labels: labels.map((label) => (label.length > 25 ? `${label.slice(0, 25)}...` : label)),
        grid: {
          display: false,
        },
      },
    },
  };

  const chartHeight =
    Math.max(labels.length, purchasedLabels.length) * 40 < 270
      ? 270
      : Math.max(labels.length, purchasedLabels.length) * 40;

  return (
    <div className={styles.bar}>
      <h6 className={styles.barTitle}>
        {selectedLicenseType === 'BitLocker' ? 'BitLocker License Allocation' : 'SecureRDP License Allocation'}
      </h6>
      <div className={styles.barChart}>
        <Bar data={chartData} options={chartOptions} height={chartHeight} />
      </div>
    </div>
  );
};

export default LicenseAllocationChart;
