import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import styles from './StatsInfo.module.scss';

const StatsInfo = () => {
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);

  const workstationPools = data?.workstationPools ?? 0;
  const serverPools = data?.serverPools ?? 0;
  const totalGroups = workstationPools + serverPools;

  return (
    <div className={styles.containerRow}>
      <div className={styles.containerCol}>
        <h5 className={styles.containerTitle}>Computer Groups</h5>
        <p className={styles.containerCount}>{isLoading ? <Skeleton width={40} /> : totalGroups}</p>
      </div>
      <div className={styles.containerCol}>
        <div className={styles.containerSubrow}>
          <div className={styles.containerSubcol}>
            <h6 className={styles.containerSmallTitle}>Workstation Pools</h6>
            <p className={styles.containerSmallCount}>{isLoading ? <Skeleton width={20} /> : workstationPools}</p>
          </div>
          <div className={styles.containerSubcol}>
            <h6 className={styles.containerSmallTitle}>Server Pools</h6>
            <p className={styles.containerSmallCount}>{isLoading ? <Skeleton width={20} /> : serverPools}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsInfo;
