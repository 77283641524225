import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import styles from './StatsInfo.module.scss';

const StatsInfo = () => {
  const { isLoading, data } = useAppSelector((state) => state.analytics.getAnalyticsRdpAttemptsRequest);

  const totalLogins = data?.data.count || 0;
  const successfulLogins = data?.data.successfulCount || 0;
  const failedLogins = data?.data.failedCount || 0;

  return (
    <div className={styles.info}>
      <div className={styles.infoRow}>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Total RDP Connections Attempts</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : totalLogins}</p>
        </div>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Successful RDP Connections</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : successfulLogins}</p>
        </div>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Failed RDP Connections</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : failedLogins}</p>
        </div>
      </div>
    </div>
  );
};

export default StatsInfo;
