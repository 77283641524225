import cn from 'classnames';
import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import styles from './StatsInfo.module.scss';

interface Props {
  isDomainAzure?: boolean;
}

const StatsInfo = ({ isDomainAzure = false }: Props) => {
  const { isLoading, data } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);
  const pooledComputers = data?.pooledComputers ?? 0;
  const nonPooledComputers = data?.nonPooledComputers ?? 0;
  const totalComputers = pooledComputers + nonPooledComputers;

  return (
    <div className={styles.containerRow}>
      <div className={cn(styles.containerCol, { [styles.azure]: isDomainAzure })}>
        <h5 className={styles.containerTitle}>Total Computers</h5>
        <p className={styles.containerCount}>{isLoading ? <Skeleton width={40} /> : totalComputers}</p>
      </div>
      {!isDomainAzure && (
        <div className={styles.containerCol}>
          <div className={styles.containerSubrow}>
            <div className={styles.containerSubcol}>
              <h6 className={styles.containerSmallTitle}>Pooled Computers</h6>
              <p className={styles.containerSmallCount}>{isLoading ? <Skeleton width={20} /> : pooledComputers}</p>
            </div>
            <div className={styles.containerSubcol}>
              <h6 className={styles.containerSmallTitle}>NonPooled Computers</h6>
              <p className={styles.containerSmallCount}>{isLoading ? <Skeleton width={20} /> : nonPooledComputers}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsInfo;
