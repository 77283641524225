import cn from 'classnames';
import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import { IDomain } from 'interfaces';
import { getDomainADStatus } from 'helpers';
import styles from './TotalInfo.module.scss';

interface Props {
  domain: IDomain;
}

const TotalInfo = ({ domain }: Props) => {
  const { isDomainAzure } = getDomainADStatus(domain);
  const { isLoading, data } = useAppSelector((state) => state.analytics.getAnalyticsUsersRequest);

  return (
    <div className={styles.info}>
      <div className={styles.infoRow}>
        <div className={cn(styles.infoCol, { [styles.azure]: isDomainAzure })}>
          <h5 className={styles.infoTitle}>Total Users</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={30} /> : data?.totalUsers}</p>
        </div>
        {!isDomainAzure && (
          <div className={styles.infoCol}>
            <h5 className={styles.infoTitle}>User Groups</h5>
            <p className={styles.infoCount}>{isLoading ? <Skeleton width={30} /> : data?.totalGroups}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalInfo;
