import cn from 'classnames';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AngleRightIcon } from 'assets/img';
import { Button, SearchInput } from 'components';
import { IDomain, IQueryConfig } from 'interfaces';
import AuthenticationFilter from 'pages/Analytics/tabs/Users/components/AuthenticationFilter/AuthenticationFilter';
import TotalRDPTable from './components/TotalRDPTable/TotalRDPTable';
import StatsInfo from './components/StatsInfo/StatsInfo';
import {
  getAnalyticsRdpAttempts,
  getAnalyticsRdpAttemptsPage,
  getAnalyticsUsers,
  IAnalyticsLoginStatsPayload,
} from 'store/slices/analytics';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from 'store';
import { notify, prepareTimeRangeConfiguration } from 'utils';
import { replaceSpecChar } from 'helpers';
import RDPGraphic from 'pages/Analytics/tabs/Users/components/TotalRDP/components/RDPGraphic/RDPGraphic';
import styles from './TotalRDP.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}

const TotalRDP = ({ domain, isCompanyPage }: Props) => {
  const history = useHistory();
  const goBack = () => {
    if (isCompanyPage) {
      return history.replace('/company-management/analytics');
    } else {
      return history.push(`/customer-management/${domain.dnsName}/analytics`);
    }
  };

  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsRdpAttemptsPageRequest);
  const { data: auth } = useAppSelector((state) => state.analytics.getAnalyticsRdpAttemptsRequest);
  const { data: users } = useAppSelector((state) => state.analytics.getAnalyticsUsersRequest);
  const dispatch = useAppDispatch();

  const { selectedUsers, selectedRange } = useAppSelector((state) => state.analytics);

  useEffect(() => {
    const fetchData = async (allUsers: string[] = selectedUsers) => {
      try {
        let userIds: string[] | null = allUsers;
        if (users && users.totalUsers > 0) {
          if (allUsers.length === 0) {
            userIds = null;
          } else if (users.totalUsers === selectedUsers.length) {
            userIds = [];
          } else {
            userIds = allUsers;
          }
        } else {
          userIds = [];
        }

        const timeRange = prepareTimeRangeConfiguration();
        const rangeData = timeRange.find((i) => i.text.toString() === selectedRange);

        const payload: IAnalyticsLoginStatsPayload = {
          domainId: domain.id,
          start: rangeData?.startDate,
          end: rangeData?.endDate,
          userIds: userIds,
        };
        const queryConfig: IQueryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'atUtc desc',
          searchQuery: '',
        };
        const promises: AsyncThunkAction<any, any, any>[] = [
          getAnalyticsRdpAttemptsPage({ payload, query: queryConfig, _background: !!data }),
        ];
        if (!auth) {
          promises.push(getAnalyticsRdpAttempts({ payload: payload, query: queryConfig }));
        }
        if (!users) {
          promises.push(getAnalyticsUsers({ domainId: domain.id }));
        }
        await Promise.all(promises.map((p) => dispatch(p).unwrap()));
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchData();
  }, []);

  const onSearch = async (value: string) => {
    const valueNew = replaceSpecChar(value);
    const queryConfig = {
      pageNumber: 1,
      searchQuery: valueNew,
    };
    await dispatch(getAnalyticsRdpAttemptsPage({ query: queryConfig })).unwrap();
  };

  return (
    <div className={styles.logins}>
      <div className={styles.loginsTitleWrap}>
        <Button icon={<AngleRightIcon />} variant="link" onClick={goBack} className={styles.loginsTitleIcon} />
        <h1 className={styles.loginsTitle}>RDP Connection Attempts</h1>
      </div>
      <div className={styles.loginsFilter}>
        <AuthenticationFilter domainId={domain.id} rdp />
      </div>
      <StatsInfo />
      <div
        className={cn(styles.loginsGraphic, {
          [styles.empty]: (!data?.data || data.data.length < 1) && !data?.queryConfig?.searchQuery,
        })}>
        <RDPGraphic />
      </div>
      {!((!data?.data || data.data.length < 1) && !data?.queryConfig?.searchQuery) && (
        <>
          <div className={styles.loginsSearch}>
            <SearchInput
              inputClassName={styles.loginsSearchInput}
              id="analytics-logins-search"
              onChange={onSearch}
              isLoading={isLoading}
              searchBy="User UPN"
            />
          </div>
          <TotalRDPTable />
        </>
      )}
    </div>
  );
};

export default TotalRDP;
