export const getPageName = (pathname: string) => {
  switch (true) {
    case pathname.includes('workspace') && !pathname.includes('customer'):
      return 'My Domain';
    case pathname.includes('customer-workspace'):
      return 'Domain Workspace';
    case pathname.includes('resource-assignments'):
      return 'Resource Assignment';
    case pathname.includes('device-management'):
      return 'Manage BitLocker Devices';
    case pathname.includes('active-directory-federation'):
      return 'Active Directory Federation';
    case pathname.includes('truscan-monitoring'):
      return 'TruScan ID Monitoring Reports';
    case pathname.includes('roles-management'):
      return 'Roles Management';
    case pathname.includes('shortcut-management'):
      return 'Shortcut Management';
    case pathname.includes('timeout-management'):
      return 'Timeout Management';
    case pathname.includes('remember-me'):
      return 'Remember me';
    case pathname.includes('azure-management'):
      return 'Azure Management';
    case pathname.includes('password-management'):
      return 'Password Management';
    case pathname.includes('phone-management'):
      return 'Phone Management';
    case pathname.includes('security-management'):
      return 'Security Management';
    case pathname.includes('domain-management'):
      return 'Domain Management';
    case pathname.includes('analytics/'):
      return 'Analytics';
    default:
      break;
  }
};

export const checkIsCompanyPage = (): boolean => {
  return window.location.href.includes('company-management');
};
