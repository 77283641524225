import { useRef } from 'react';
import cn from 'classnames';
import Link from 'components/Link/Link';
import { CompanyIcon, MinusIcon, PlusIcon } from 'assets/img';
import { corpAdmin, corpTechAdmin, custAdmin, spTechAdmin, techAdmin } from 'consts';
import { useAppSelector } from 'store';
import styles from '../NavbarItem.module.scss';
import { useLocation } from 'react-router-dom';

interface Props {
  role: string;
  isCompanyOpen: boolean;
  domainStatus: number;
  onCompanyToggle: () => void;
  blockLeftMenu?: boolean;
}

const CompanyItems = ({ role, isCompanyOpen, domainStatus, onCompanyToggle, blockLeftMenu }: Props) => {
  const location = useLocation<Location>();
  const { branding } = useAppSelector((state) => state.user);
  const { useInvertedColorScheme: isInvertedColors } = branding || {};
  const color = isInvertedColors ? '#000' : '#fff';

  const nodeRef = useRef<HTMLUListElement>(null);
  let companyPages = [
    {
      label: 'Resource Assignment',
      link: '/company-management/resource-assignments',
    },
    {
      label: 'BitLocker Management',
      link: '/company-management/device-management',
    },
    {
      label: 'Active Directory Federation',
      link: '/company-management/active-directory-federation',
    },
    {
      label: 'Roles Management',
      link: '/company-management/roles-management',
    },
    {
      label: 'Security Management',
      link: '/company-management/security-management',
      subPages: [
        '/company-management/security-management/shortcut-management',
        '/company-management/security-management/timeout-management',
        '/company-management/security-management/remember-me',
      ],
    },
    {
      label: 'Domain Management',
      link: '/company-management/domain-management',
    },
    {
      label: 'Analytics',
      link: '/company-management/analytics',
      subPages: ['/company-management/analytics/logins', '/company-management/analytics/rdp'],
    },
  ];

  if ([custAdmin, corpTechAdmin].includes(role)) {
    companyPages = companyPages.filter(
      (i) => !['Active Directory Federation', 'TruScan ID Monitoring Reports', 'Roles Management'].includes(i.label),
    );
  }
  if ([custAdmin, techAdmin].includes(role)) {
    companyPages = companyPages.filter((i) => !['Domain Management'].includes(i.label));
  }
  if ([spTechAdmin, techAdmin].includes(role)) {
    companyPages = companyPages.filter((i) => i.label !== 'Roles Management');
  }
  if (role === corpAdmin) {
    companyPages = companyPages.filter(
      (i) => !['Active Directory Federation', 'TruScan ID Monitoring Reports'].includes(i.label),
    );
  }

  return (
    <>
      <li
        className={cn(styles.item, {
          [styles.itemActive]: location.pathname.includes('company-management') && !isCompanyOpen,
          [styles.itemDisabled]: !domainStatus || blockLeftMenu,
          [styles.branding]: branding && !isInvertedColors,
          [styles.inverted]: isInvertedColors,
        })}
        id="company-management"
        data-tip="Manage my company account and resources"
        data-iscapture="true"
        onClick={onCompanyToggle}>
        <span className={styles.itemLink}>
          <CompanyIcon />
          <span>Company</span>
          {isCompanyOpen ? <MinusIcon /> : <PlusIcon />}
        </span>
      </li>
      {isCompanyOpen && (
        <ul className={styles.itemSublist} ref={nodeRef}>
          {companyPages.map((item) => (
            <li
              key={item.label}
              className={cn(styles.item, {
                [styles.active]:
                  location.pathname.includes('company-management') &&
                  (location.pathname === item.link || item?.subPages?.includes(location.pathname)),
                [styles.branding]: branding && !isInvertedColors,
                [styles.inverted]: isInvertedColors,
              })}>
              <span style={branding?.backGroundColor ? { background: color } : {}} />
              <Link
                to={item.link}
                id={item.label.split(' ').join('-').toLowerCase()}
                className={cn(styles.itemLink, styles.itemSublistLink)}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default CompanyItems;
