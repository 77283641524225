import ComputerGroups from './components/ComputerGroups/ComputerGroups';
import DirectoryServices from './components/DirectoryServices/DirectoryServices';
import OSType from './components/OSType/OSType';
import TotalComputers from './components/TotalComputers/TotalComputers';
import styles from './Computers.module.scss';

const Computers = () => (
  <div className={styles.computers}>
    <div className={styles.computersRow}>
      <div className={styles.computersCol}>
        <TotalComputers />
      </div>
      <div className={styles.computersCol}>
        <ComputerGroups />
      </div>
    </div>
    <div className={styles.computersRow}>
      <div className={styles.computersCol}>
        <DirectoryServices />
      </div>
      <div className={styles.computersCol}>
        <OSType />
      </div>
    </div>
  </div>
);

export default Computers;
