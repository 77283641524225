import { useRouteMatch } from 'react-router-dom';
import { Link } from 'components';
import StatsInfo from './components/StatsInfo';
import RDPGraphic from './components/RDPGraphic/RDPGraphic';
import styles from './TotalRDP.module.scss';

const TotalRDP = () => {
  const { url } = useRouteMatch();

  return (
    <div className={styles.container}>
      <StatsInfo />
      <div className={styles.containerGraphic}>
        <RDPGraphic />
      </div>
      <div className={styles.link}>
        <Link to={`${url}/rdp`}>View details</Link>
      </div>
    </div>
  );
};

export default TotalRDP;
