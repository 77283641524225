import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const layout = document.querySelector('#layout');
    if (layout) layout.scrollTop = 0;
  }, [pathname]);

  return null;
};

export default ScrollToTop;
