import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import styles from './StatsInfo.module.scss';

const StatsInfo = () => {
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);
  const pooledComputers = data?.pooledComputers || 0;
  const nonPooledComputers = data?.nonPooledComputers || 0;
  const totalComputers = pooledComputers + nonPooledComputers;

  return (
    <div className={styles.info}>
      <div className={styles.infoRow}>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Total Computers</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : totalComputers}</p>
        </div>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Pooled Computers</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : pooledComputers}</p>
        </div>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>NonPooled Computers</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : nonPooledComputers}</p>
        </div>
      </div>
    </div>
  );
};

export default StatsInfo;
