import cn from 'classnames';
import { Table } from 'components';
import { Column } from 'components/Table/Table';
import styles from './TotalLoginsTable.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { IAnalyticsAuthenticationPage } from 'interfaces/analytics';
import { getAnalyticsAuthenticationPage } from 'store/slices/analytics';
import { IQueryConfig } from 'interfaces';
import { dateFormatterUtc } from 'helpers';

const TotalLoginsTable = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsAuthenticationPageRequest);

  const columns: Column[] = [
    {
      name: 'UPN',
      width: '35%',
      sortable: 'upn',
      data: (item: IAnalyticsAuthenticationPage) => (item.userName ? item.userName : 'N/A'),
    },
    {
      name: 'Status',
      width: '15%',
      sortable: 'status',
      data: (item: IAnalyticsAuthenticationPage) => (
        <span className={cn(styles.tableStatus, { [styles.failed]: !item.status })}>
          {item.status ? 'Successful' : 'Failed'}
        </span>
      ),
    },
    {
      name: 'Time Stamp',
      width: '30%',
      sortable: 'atUtc',
      data: (item: IAnalyticsAuthenticationPage) => {
        const date = dateFormatterUtc(item.atUtc);
        return date;
      },
    },
    {
      name: 'IP',
      width: '20%',
      data: (item: IAnalyticsAuthenticationPage) => item.ipAddress,
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getAnalyticsAuthenticationPage({ query, _background: true })).unwrap();
  };

  return (
    <Table<IAnalyticsAuthenticationPage>
      id="analytics-logins-table"
      className={styles.table}
      dataKey="userId"
      indexWithIndex
      columns={columns}
      isLoading={isLoading}
      dispatchAction={dispatchAction}
      data={{
        data: data?.data || [],
        queryConfig: data?.queryConfig,
        pageMeta: data?.pageMeta,
      }}
    />
  );
};

export default TotalLoginsTable;
