import cn from 'classnames';
import { Table } from 'components';
import { Column } from 'components/Table/Table';
import { IAnalyticsRdpАttempt } from 'interfaces/analytics';
import { useAppDispatch, useAppSelector } from 'store';
import { getAnalyticsRdpAttemptsPage } from 'store/slices/analytics';
import { IQueryConfig } from 'interfaces';
import styles from './TotalRDPTable.module.scss';
import { dateFormatterUtc } from 'helpers';

const TotalRDPTable = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsRdpAttemptsPageRequest);

  const columns: Column[] = [
    {
      name: 'UPN',
      width: '30%',
      sortable: 'userName',
      data: (item: IAnalyticsRdpАttempt) => (item.userName ? item.userName : 'N/A'),
    },
    {
      name: 'Status',
      width: '20%',
      sortable: 'state',
      data: (item: IAnalyticsRdpАttempt) => (
        <span className={cn(styles.tableStatus, { [styles.failed]: !item.state })}>
          {item.state ? 'Successful' : 'Failed'}
        </span>
      ),
    },
    {
      name: 'Time Stamp',
      width: '25%',
      sortable: 'atUtc',
      data: (item: IAnalyticsRdpАttempt) => {
        const date = dateFormatterUtc(item.atUtc);
        return date;
      },
    },
    {
      name: 'Additional info',
      width: '25%',
      data: (item: IAnalyticsRdpАttempt) => (
        <ul className={styles.tableList}>
          <li className={styles.tableListItem}>
            <span className={styles.tableListKey}>Source IP:</span>
            <span className={styles.tableListValue}>{' ' + item.ipAddress}</span>
          </li>
          <li className={styles.tableListItem}>
            <span className={styles.tableListKey}>Source system type:</span>
            <span className={styles.tableListValue}>{' ' + item.sourceOS}</span>
          </li>
          <li className={styles.tableListItem}>
            <span className={styles.tableListKey}>Target name:</span>
            <span className={styles.tableListValue}>{' ' + item.targetName}</span>
          </li>
          <li className={styles.tableListItem}>
            <span className={styles.tableListKey}>Target OS: </span>
            <span className={styles.tableListValue}>{' ' + item.targetOS}</span>
          </li>
        </ul>
      ),
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getAnalyticsRdpAttemptsPage({ query, _background: true }));
  };

  return (
    <Table<IAnalyticsRdpАttempt>
      id="analytics-rdp-table"
      className={styles.table}
      classNameRow={styles.tableRowBaseline}
      dataKey="connectionId"
      columns={columns}
      isLoading={isLoading}
      data={{
        data: data?.data,
        queryConfig: data?.queryConfig,
        pageMeta: data?.pageMeta,
      }}
      dispatchAction={dispatchAction}
    />
  );
};

export default TotalRDPTable;
