import { Chart, ChartArea, ChartDataset, ChartOptions } from 'chart.js';

interface Stats {
  successful: { x: number; y: number }[];
  failed: { x: number; y: number }[];
}

export const chartConfig = (
  successfulLoginsStats: { x: Date; y: number }[],
  failedLoginsStats: { x: Date; y: number }[],
) => ({
  datasets: [
    {
      label: 'Failed logins',
      type: 'line',
      data: [...failedLoginsStats],
      fill: false,
      tension: 0,
      pointRadius: 5,
      pointHoverRadius: 7,
      borderColor: '#F3657B',
      backgroundColor: '#F3657B',
      pointBordessrColor: '#F3657B',
      pointBackgroundColor: '#F3657B',
      pointHoverBackgroundColor: '#f1526b',
      pointHoverBorderColor: '#f1526b',
      datalabels: {
        display: false,
      },
    },
    {
      label: 'Successful logins',
      type: 'line',
      data: [...successfulLoginsStats],
      fill: false,
      tension: 0,
      pointRadius: 5,
      pointHoverRadius: 7,
      borderColor: '#7E74F9',
      backgroundColor: '#7E74F9',
      pointBordessrColor: '#7E74F9',
      pointBackgroundColor: '#7E74F9',
      pointHoverBackgroundColor: '##6c61f7',
      pointHoverBorderColor: '##6c61f7',
      datalabels: {
        display: false,
      },
    },
  ],
});

export const chartOptions = (successfulLoginsStats: { x: Date; y: number }[]) => {
  const labels = [];

  for (const stat of successfulLoginsStats) {
    const date = new Date(Date.parse(stat.x.toString()));
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    labels.push(`${monthName} ${date.getDate()}`);
  }

  return {
    responsive: true,
    labels,
    elements: {
      line: { fill: false },
    },
    scales: {
      x: {
        display: true,
        grid: { borderDash: [8, 4] },
        labels,
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: { borderDash: [8, 4] },
        labels: { show: true },
        min: 0,
        ticks: {
          callback: (value: number) => (value % 1 === 0 ? value : null),
        },
      },
    },
    tooltips: { caretPadding: 10 },
  };
};

function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea, colors: [string, string]) {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, colors[0]);
    gradient.addColorStop(0, colors[1]);
  }

  return gradient;
}

export const analyticsChartConfig = (
  successfulLoginsStats: Stats['successful'],
  failedLoginsStats: Stats['failed'],
) => {
  const datasets: ChartDataset<'line'>[] = [
    {
      label: 'Successful',
      type: 'line',
      data: [...successfulLoginsStats],
      fill: true,
      tension: 0.2,
      borderColor: '#61B8FF',
      borderWidth: 1.5,
      pointBackgroundColor: '#61B8FF',
      pointBorderWidth: 1,
      backgroundColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return;
        }
        return getGradient(ctx, chartArea, ['rgba(97, 184, 255, 0.2)', 'rgba(97, 184, 255, 0)']);
      },
      datalabels: {
        display: false,
      },
    },
    {
      label: 'Failed',
      type: 'line',
      data: [...failedLoginsStats],
      fill: true,
      tension: 0.2,
      borderColor: '#F8866A',
      borderWidth: 1.5,
      pointBackgroundColor: '#F8866A',
      pointBorderWidth: 1,
      backgroundColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return;
        }
        return getGradient(ctx, chartArea, ['rgba(232, 101, 54, 0.2)', 'rgba(232, 101, 54, 0)']);
      },
      datalabels: {
        display: false,
      },
    },
  ];

  return { datasets };
};

export const analyticsChartOptions = (successfulLoginsStats: { x: number; y: number }[]) => {
  const labels = [];

  for (const stat of successfulLoginsStats) {
    const date = new Date(stat.x);
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    labels.push(`${monthName} ${date.getDate()}`);
  }

  const options: ChartOptions<'line'> = {
    responsive: true,
    animation: false,
    elements: {
      line: { fill: false },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        labels,
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: { borderDash: [1.5, 1.5] },
        min: 0,
        ticks: {
          callback: (value) => (Number(value) % 1 === 0 ? value : null),
        },
      },
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
          weight: '600',
        },
        bodyFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
        },
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => ({
            pointStyle: 'circle',
            rotation: 0,
          }),
        },
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          padding: 12,
          font: {
            family: '"IBM Plex Sans", sans-serif',
            size: 12,
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
    },
  };

  return options;
};

export const getHtmlLegendPlugin = (id: string, styles: any) => ({
  id: id,
  afterUpdate(chart: Chart) {
    const items = chart.legend?.legendItems || [];
    const legendContainer = document.getElementById(id);
    if (legendContainer) {
      legendContainer.innerHTML = '';
      items.forEach((item) => {
        const os = item.text.split('|');
        const itemContainer = document.createElement('div');
        itemContainer.className = styles.legend;
        const itemValueContainer = document.createElement('div');
        itemValueContainer.className = styles.legendRow;
        const itemColor = document.createElement('div');
        itemColor.className = styles.legendColor;
        itemColor.style.backgroundColor = item.fillStyle as string;
        const itemText = document.createElement('p');
        itemText.className = styles.legendText;
        const itemValue = document.createElement('p');
        itemText.className = styles.legendValue;
        itemText.textContent = os[0];
        itemValue.textContent = os[1];
        itemValueContainer.appendChild(itemText);
        itemValueContainer.appendChild(itemValue);
        itemContainer.appendChild(itemColor);
        itemContainer.appendChild(itemValueContainer);
        legendContainer.appendChild(itemContainer);
      });
    }
  },
});
