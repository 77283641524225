import { IDomain } from 'interfaces';
import DirectoryServices from './components/DirectoryServices/DirectoryServices';
import OSType from './components/OSType/OSType';
import TotalComputers from './components/TotalComputers/TotalComputers';
import styles from './Computers.module.scss';
import EntraIDTable from './components/TotalComputers/components/EntraIDTable/EntraIDTable';

interface Props {
  domain: IDomain;
  isDomainAzure: boolean;
}

const ComputersEntraID = ({ domain, isDomainAzure }: Props) => (
  <>
    <div className={styles.computers}>
      <div className={styles.computersRow}>
        <div className={styles.computersCol}>
          <TotalComputers isDomainAzure={isDomainAzure} />
        </div>
      </div>
      <div className={styles.computersRow}>
        <div className={styles.computersCol}>
          <DirectoryServices />
        </div>
        <div className={styles.computersCol}>
          <OSType />
        </div>
      </div>
    </div>

    {isDomainAzure && <EntraIDTable domain={domain} isDomainAzure={isDomainAzure} />}
  </>
);

export default ComputersEntraID;
