import { Link } from 'components';
import ComputerGroupGraphic from './components/ComputerGroupGraphic/ComputerGroupGraphic';
import StatsInfo from './components/StatsInfo/StatsInfo';
import { useRouteMatch } from 'react-router-dom';
import styles from './ComputerGroups.module.scss';
import { useAppSelector } from 'store';

const ComputerGroups = () => {
  const { url } = useRouteMatch();

  const { data } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);
  const workstationPools = data?.workstationPools ?? 0;
  const serverPools = data?.serverPools ?? 0;
  const totalGroups = workstationPools + serverPools;

  return (
    <div className={styles.container}>
      <StatsInfo />
      <div className={styles.containerGraphic}>
        <ComputerGroupGraphic />
      </div>
      {totalGroups > 0 && (
        <div className={styles.link}>
          <Link to={`${url}/computer-groups`}>View details</Link>
        </div>
      )}
    </div>
  );
};

export default ComputerGroups;
