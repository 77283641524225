/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styles from './DirectoryServices.module.scss';
import { useAppSelector } from 'store';
import { getHtmlLegendPlugin } from 'utils';

const DirectoryServices = () => {
  const { data: computers } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);

  const domainJoined = computers?.domainJoinedMachines ?? 0;
  const entraIdJoined = computers?.entraIdJoinedMachines ?? 0;
  const standalone = computers?.standaloneMachines ?? 0;
  const total = domainJoined + entraIdJoined + standalone;

  const htmlLegendPlugin = getHtmlLegendPlugin('legend-container', styles);

  const labels = ['Domain Joined', 'EntraID Joined', 'Standalone'];
  const data = [domainJoined, entraIdJoined, standalone];

  const filteredLabels = labels.filter((_, index) => data[index] > 0);
  const filteredData = data.filter((value) => value > 0);

  const chartData: ChartData<'doughnut'> = {
    labels: filteredLabels,
    datasets: [
      {
        data: filteredData,
        backgroundColor: ['#3673E8', '#61B8FF', '#FD9588'].filter((_, index) => data[index] > 0),
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    maintainAspectRatio: false,
    animation: false,
    cutout: '60%',
    offset: 0,
    plugins: {
      tooltip: {
        titleFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
          weight: '600',
        },
        bodyFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
        },
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => ({
            pointStyle: 'circle',
            rotation: 0,
          }),
          label: (d) => {
            const percentage = ` ${d.label} ${((Number(d.raw) / total) * 100).toFixed(2)}%`;
            return percentage;
          },
        },
      },
      legend: {
        display: false,
        position: 'right',
        labels: {
          generateLabels(chart) {
            const dataset = chart.data.datasets[0];
            const labels = chart.data.labels;
            const legendItems = labels!.map((label, i) => {
              const percentage = (Number(dataset.data[i]) / total) * 100;
              return {
                text: `${label}|${percentage.toFixed(2)}%`,
                fillStyle: (dataset.backgroundColor as string[])[i],
                hidden: dataset.data[i] === 0,
                index: i,
              };
            });
            return legendItems;
          },
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <h5 className={styles.containerTitle}>Directory Services</h5>
      <div className={styles.containerRow}>
        <div className={styles.containerGraphic}>
          <Doughnut data={chartData} options={options} plugins={[htmlLegendPlugin]} height="100%" />
        </div>
        <div id="legend-container" />
      </div>
    </div>
  );
};

export default DirectoryServices;
