import { ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useAppSelector } from 'store';
import { getHtmlLegendPlugin } from 'utils';
import styles from './ComputerGroupGraphic.module.scss';

const ComputerGroupGraphic = () => {
  const { data: computers } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);

  const serverPools = computers?.serverPools ?? 0;
  const workstationPools = computers?.workstationPools ?? 0;
  const totalGroups = workstationPools + serverPools;
  const data = [serverPools, workstationPools];
  const labels = ['Server Pools', 'Workstation Pools'];

  const htmlLegendPlugin = getHtmlLegendPlugin('legend-groups-container', styles);

  // Filter out data with value 0
  const filteredData = data.filter((value) => value !== 0);
  const filteredLabels = labels.filter((_, index) => data[index] !== 0);

  const chartData: ChartData<'doughnut'> = {
    labels: filteredLabels,
    datasets: [
      {
        data: filteredData,
        backgroundColor: ['#3673E8', '#61B8FF'],
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    maintainAspectRatio: false,
    animation: false,
    cutout: '60%',
    offset: 0,
    plugins: {
      tooltip: {
        titleFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
          weight: '600',
        },
        bodyFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
        },
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => ({
            pointStyle: 'circle',
            rotation: 0,
          }),
          label: (d) => {
            const percentage = ` ${d.label} ${((Number(d.raw) / totalGroups) * 100).toFixed(2)}%`;
            return percentage;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          generateLabels(chart) {
            const dataset = chart.data.datasets[0];
            const labels = chart.data.labels;
            const legendItems = labels!.map((label, i) => {
              const percentage = (Number(dataset.data[i]) / totalGroups) * 100;
              return {
                text: `${label}|${percentage.toFixed(2)}%`,
                fillStyle: (dataset.backgroundColor as string[])[i],
                hidden: dataset.data[i] === 0,
                index: i,
              };
            });
            return legendItems;
          },
        },
      },
    },
  };

  return (
    <div className={styles.containerRow}>
      <div className={styles.containerGraphic}>
        <Doughnut data={chartData} options={options} plugins={[htmlLegendPlugin]} height="100%" />
      </div>
      <div id="legend-groups-container" />
    </div>
  );
};

export default ComputerGroupGraphic;
