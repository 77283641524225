import { Button, Table } from 'components';
import { Column } from 'components/Table/Table';
import { useAppDispatch, useAppSelector } from 'store';
import { IAnalyticsComputersGroup } from 'interfaces/analytics';
import { GroupMachineIcon } from 'assets/img';
import { useState } from 'react';
import { IQueryConfig } from 'interfaces';
import styles from './ComputerGroupsTable.module.scss';
import { getAnalyticsComputersGroupsDetailed } from 'store/slices/analytics';

const ComputerGroupsTable = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsComputersGroupsDetailedRequest);
  const [openGroupId, setOpenGroupId] = useState<string>('');
  const toggleGroups = (machineId: string) => setOpenGroupId(machineId === openGroupId ? '' : machineId);

  const columns: Column[] = [
    {
      name: 'Computer Group',
      width: '30%',
      sortable: 'name',
      data: (item: IAnalyticsComputersGroup) => (
        <div className={styles.tableName}>
          <GroupMachineIcon />
          <div className="truncate-text">{item.name}</div>
        </div>
      ),
    },
    {
      name: 'Group Type',
      width: '30%',
      data: (item: IAnalyticsComputersGroup) => (item.isServerPool ? 'Server Pool' : 'Workstation Pool'),
    },
    {
      name: 'Number of Computers',
      width: '20%',
      sortable: 'numberOfComputers',
      data: 'numberOfComputers',
    },
    {
      name: 'Computer Name',
      width: '20%',
      data: (item: IAnalyticsComputersGroup) => {
        if (item.members.length > 0) {
          const isOpenGroups = openGroupId === item.id;
          const groups = isOpenGroups ? item.members : item.members.slice(0, 3);
          return (
            <div className={styles.tableGroup}>
              {groups.map((group) => (
                <span key={group}>{group}</span>
              ))}
              {item.members.length > 3 && (
                <Button variant="link" className={styles.tableGroupMore} onClick={() => toggleGroups(item.id)}>
                  {isOpenGroups ? 'Hide' : 'Show more'}
                </Button>
              )}
            </div>
          );
        } else {
          return '-';
        }
      },
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getAnalyticsComputersGroupsDetailed({ query, _background: true }));
  };

  return (
    <Table<IAnalyticsComputersGroup>
      id="computers-detailed-table"
      className={styles.table}
      classNameRow={(item: IAnalyticsComputersGroup) => (item.members.length > 1 ? styles.tableRowBaseline : '')}
      dataKey="id"
      columns={columns}
      isLoading={isLoading}
      data={{
        data: data?.data,
        queryConfig: data?.queryConfig,
        pageMeta: data?.pageMeta,
      }}
      dispatchAction={dispatchAction}
    />
  );
};

export default ComputerGroupsTable;
