/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styles from './OSType.module.scss';
import { useAppSelector } from 'store';
import { getHtmlLegendPlugin } from 'utils';

const backgroundColors = [
  '#C8E8FF',
  '#3673E8',
  '#61B8FF',
  '#96C3D8',
  '#00D7ED',
  '#FD9588',
  '#F5B763',
  '#63F5AC',
  '#3633D7',
];

const OSType = () => {
  const { data } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);

  const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  };

  const labels = data?.osTypes ? Object.keys(data?.osTypes) : [];
  const values = data?.osTypes ? Object.values(data?.osTypes) : [];
  const backgroundColor = labels.map((_, i) => backgroundColors[i]);
  const total = values.reduce((acc, value) => acc + value, 0);

  const htmlLegendPlugin = getHtmlLegendPlugin('legend-os-container', styles);

  const chartData: ChartData<'doughnut'> = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColor,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    animation: false,
    maintainAspectRatio: false,
    cutout: '60%',
    offset: 0,
    plugins: {
      tooltip: {
        titleFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
          weight: '600',
        },
        bodyFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
        },
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => ({
            pointStyle: 'circle',
            rotation: 0,
          }),
          label: (d) => {
            const percentage = ` ${capitalizeFirstLetter(d.label)} ${((Number(d.raw) / total) * 100).toFixed(2)}%`;
            return percentage;
          },
        },
      },
      legend: {
        display: false,
        position: 'right',
        labels: {
          generateLabels(chart) {
            const dataset = chart.data.datasets[0];
            const labels = chart.data.labels;
            const legendItems = labels!.map((label, i) => {
              const percentage = (Number(dataset.data[i]) / total) * 100;
              return {
                text: `${capitalizeFirstLetter(label as string)}|${percentage.toFixed(2)}%`,
                fillStyle: (dataset.backgroundColor as string[])[i],
                hidden: dataset.data[i] === 0,
                index: i,
              };
            });
            return legendItems;
          },
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <h5 className={styles.containerTitle}>OS Type</h5>
      <div className={styles.containerRow}>
        <div className={styles.containerGraphic}>
          <Doughnut data={chartData} options={options} plugins={[htmlLegendPlugin]} height="100%" />
        </div>
        <div id="legend-os-container" />
      </div>
    </div>
  );
};

export default OSType;
