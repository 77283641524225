import { Line } from 'react-chartjs-2';
import { useAppSelector } from 'store';
import { analyticsChartConfig, analyticsChartOptions } from 'utils';

interface Stats {
  successful: { x: any; y: number }[];
  failed: { x: any; y: number }[];
}

const RDPGraphic = () => {
  const { data: rpds } = useAppSelector((state) => state.analytics.getAnalyticsRdpAttemptsRequest);

  const stats: Stats = { successful: [], failed: [] };
  if (rpds?.data.dailyStats) {
    stats.successful = rpds.data.dailyStats.map((item) => ({
      x: new Date(item.day),
      y: item.successfull,
    }));
    stats.failed = rpds.data.dailyStats.map((item) => ({
      x: new Date(item.day),
      y: item.failed,
    }));
  }

  const data = analyticsChartConfig(stats.successful, stats.failed);
  const options = analyticsChartOptions(stats.successful);
  options.maintainAspectRatio = false;

  return <Line data={data} options={options} height="100%" />;
};

export default RDPGraphic;
