import cn from 'classnames';
import { Link } from 'components';
import ComputerGraphic from './components/ComputerGraphic/ComputerGraphic';
import StatsInfo from './components/StatsInfo/StatsInfo';
import { useRouteMatch } from 'react-router-dom';
import styles from './TotalComputers.module.scss';
import { useAppSelector } from 'store';

interface Props {
  isDomainAzure?: boolean;
}

const TotalComputers = ({ isDomainAzure = false }: Props) => {
  const { url } = useRouteMatch();

  const { data } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);
  const pooledComputers = data?.pooledComputers ?? 0;
  const nonPooledComputers = data?.nonPooledComputers ?? 0;
  const totalComputers = pooledComputers + nonPooledComputers;

  return (
    <div className={cn(styles.container, { [styles.azure]: isDomainAzure })}>
      <StatsInfo isDomainAzure={isDomainAzure} />
      {!isDomainAzure && (
        <>
          <div className={styles.containerGraphic}>
            <ComputerGraphic />
          </div>
          {totalComputers > 0 && (
            <div className={styles.link}>
              <Link to={`${url}/computers`}>View details</Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TotalComputers;
