import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DropdownArray, Link, Loader } from 'components';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import LicensesDomainChart from './components/LicensesDomainChart/LicensesDomainChart';
import LicenseAllocationChart from './components/LicenseAllocationChart/LicenseAllocationChart';
import { getAnalyticsLicenses } from 'store/slices/analytics';
import styles from './Licensing.module.scss';

interface Props {
  domain: IDomain;
  userRole: string;
}

const Licensing = ({ domain }: Props) => {
  const { url } = useRouteMatch();

  const dispatch = useAppDispatch();

  const [selectedLicenseType, setSelectedLicenseType] = useState('SecureRDP');
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsLicensesRequest);

  const allLicensesCount = data?.totalCount || 0;

  const onChangeLicenseType = async (selectedType: 'SecureRDP' | 'BitLocker') => {
    const type = selectedType === 'SecureRDP' ? 'rdp' : 'bitlocker';
    await dispatch(getAnalyticsLicenses({ type, domainId: domain.id }));
    setSelectedLicenseType(selectedType);
  };

  if (isLoading) {
    return <Loader id="loading-licensing-tab" />;
  }

  return (
    <div className={styles.licensing}>
      <div className={styles.licensingWrap}>
        <div className={styles.licensingFilter}>
          <DropdownArray
            id="reports-select-licenses"
            isSmall={false}
            isSearch={false}
            options={['SecureRDP', 'BitLocker']}
            defaultValue={selectedLicenseType}
            onChange={(value: any) => onChangeLicenseType(value)}
            width="12em"
            isFilter
          />
          <div className={styles.licensingStat}>
            Total Purchased Licenses <strong>({allLicensesCount})</strong>
          </div>
        </div>
        <Link to={`${url}/licensing`} bold>
          View details
        </Link>
      </div>

      <div className={styles.licensingRow}>
        <div className={styles.licensingCol}>
          <LicensesDomainChart selectedLicenseType={selectedLicenseType} />
        </div>
        <div className={styles.licensingCol}>
          <LicenseAllocationChart selectedLicenseType={selectedLicenseType} />
        </div>
      </div>
    </div>
  );
};

export default Licensing;
