import { memo, useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  LoaderSmallIcon,
  AngleUpIcon,
  FileDownloadIcon,
  LinkCopyIcon,
  CloseIcon,
  TrashIcon,
  CheckIcon,
  AngleDownIcon,
} from 'assets/img';
import { CSSTransition } from 'react-transition-group';
import Button from 'components/Button/Button';
import styles from './DropdownArray.module.scss';

export interface DropdownOption {
  id?: string;
  value: string;
  icon?: string;
}

interface Props {
  id?: string;
  label?: string;
  options: DropdownOption[] | string[];
  optionsDisabled?: string[];
  openIcon?: boolean;
  icon?: React.ReactNode;
  onChange: (value: string | DropdownOption) => Promise<void> | void;
  defaultValue?: string;
  defaultValueHtml?: React.ReactNode;
  allowPositionTop?: boolean;
  isSmall?: boolean;
  isSearch?: boolean;
  isButton?: boolean;
  isButton32?: boolean;
  isDisabled?: boolean;
  isFilter?: boolean;
  strightRight?: boolean;
  btnTitle?: any;
  customField?: string;
  onCustomEvent?: () => void;
  style?: any;
  className?: any;
  width?: string;
  optionsClassName?: any;
}

const ICONS_MAP: Record<string, React.FC> = {
  download: FileDownloadIcon,
  copy: LinkCopyIcon,
  cancel: CloseIcon,
  delete: TrashIcon,
};

const DropdownArray = ({
  id,
  label,
  options,
  optionsDisabled,
  openIcon = true,
  icon,
  onChange,
  defaultValue,
  defaultValueHtml,
  isSmall,
  isSearch,
  isButton,
  isButton32,
  isDisabled,
  isFilter,
  strightRight,
  btnTitle,
  customField,
  onCustomEvent,
  style,
  className,
  allowPositionTop,
  width,
  optionsClassName,
}: Props) => {
  const [filteredOptions, setFilteredOptions] = useState<DropdownOption[] | string[]>(options);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const [dropdownTop, setDropdownTop] = useState(false);

  const calculateDropdownPosition = (
    button: HTMLDivElement | null,
    node: HTMLDivElement | null,
    action: (value: boolean) => void,
  ) => {
    if (button && node) {
      const windowHeight = window.innerHeight;
      const menuHeight = node.getBoundingClientRect().height;
      const instOffsetWithMenu = button.getBoundingClientRect().bottom + menuHeight;
      return action(instOffsetWithMenu > windowHeight);
    }
    return true;
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const toggleOpenDropdown = () => {
    if (isOpen && filteredOptions.length !== options.length) {
      setTimeout(() => setFilteredOptions(options), 200);
    }
    setIsOpen(!isOpen);
    if (allowPositionTop) {
      setTimeout(() => {
        calculateDropdownPosition(dropdownRef?.current, nodeRef?.current, setDropdownTop);
        window.addEventListener('resize', () => {
          calculateDropdownPosition(dropdownRef?.current, nodeRef?.current, setDropdownTop);
        });
      }, 0);
    }
  };

  const onClose = () => {
    if (filteredOptions.length !== options.length) setFilteredOptions(options);
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => onClose());

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value) {
      const result = options.filter((item) => {
        if (typeof item === 'string') {
          return item.toLowerCase().includes(value.toLowerCase());
        } else {
          return item.value.toLowerCase().includes(value.toLowerCase());
        }
      });
      setFilteredOptions(typeof result === 'string' ? (result as string[]) : (result as DropdownOption[]));
    } else {
      setFilteredOptions(options);
    }
  };

  const onSelectHandler = async (
    value: string,
    disabled: boolean | undefined,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (disabled) return false;

    e.stopPropagation();

    if (isFilter) {
      try {
        setIsLoading(true);

        onClose();
        await onChange(value);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      onClose();
      await onChange(value);
    }
  };

  const wrapperClasses = cn(styles.dropdown, {
    [styles.dropdownTypeButton]: isButton,
  });

  return (
    <div
      id={id}
      className={wrapperClasses}
      style={style}
      ref={dropdownRef}
      {...(isDisabled || isLoading ? { disabled: true } : {})}>
      {label && <div className={styles.dropdownLabel}>{label}</div>}
      {isButton ? (
        <Button
          variant="primary"
          className={cn(styles.dropdownIsButton, {
            [styles.dropdownIsButtonActive]: isOpen,
            [className]: className,
          })}
          iconPosition="right"
          style={{ width }}
          isDisabled={isDisabled}
          icon={isLoading ? <LoaderSmallIcon id="loading-dropdown" /> : isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          size={isButton32 ? '32' : '40'}
          onClick={toggleOpenDropdown}>
          <span className={styles.dropdownValue}>
            {icon && icon}
            {isButton || isButton32 ? btnTitle : defaultValueHtml || defaultValue}
          </span>
        </Button>
      ) : (
        <button
          type="button"
          style={{ width }}
          className={cn(styles.dropdownButton, {
            [styles.dropdownButtonActive]: isOpen,
            active: isOpen,
            [styles.dropdownButtonSmall]: isSmall,
            [styles.dropdownButtonIcon]: icon,
            [styles.dropdownButtonStrightRight]: strightRight,
            [styles.disabled]: isDisabled,
            [className]: className,
          })}
          onClick={toggleOpenDropdown}>
          {icon && icon}
          <span className={styles.dropdownValue}>
            {isButton || isButton32 ? btnTitle : defaultValueHtml || defaultValue}
          </span>
          {openIcon && (isLoading ? <LoaderSmallIcon id="loading" /> : isOpen ? <AngleUpIcon /> : <AngleDownIcon />)}
        </button>
      )}
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}>
        <div
          className={cn(styles.dropdownOptions, { [styles.top]: dropdownTop, [optionsClassName]: optionsClassName })}
          style={{ width }}
          ref={nodeRef}>
          {isSearch && (
            <input
              type="search"
              className={styles.dropdownOptionsSearch}
              placeholder="Search..."
              onChange={onSearchHandler}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          {filteredOptions.length ? (
            filteredOptions.map((option: any, index: number) => {
              if (option === customField) {
                return (
                  <button
                    type="button"
                    id={option?.id}
                    key={customField}
                    className={cn(styles.dropdownOptionsItem, styles.dropdownOptionsItemCustom)}
                    onClick={onCustomEvent}>
                    {option}
                  </button>
                );
              }
              if (option.icon) {
                const Icon = ICONS_MAP[option.icon];
                const optionDisabled = optionsDisabled?.includes(option.value) || false;

                return (
                  <button
                    type="button"
                    id={option?.id}
                    key={option.id}
                    className={cn(styles.dropdownOptionsItem, styles.dropdownOptionsItemIcon, {
                      [styles.dropdownOptionsItemDisabled]: optionDisabled,
                    })}
                    onClick={(e: any) => onSelectHandler(option.value, optionDisabled, e)}>
                    <Icon /> {option.value}
                  </button>
                );
              }
              if (typeof option === 'object' && option.value && option.id) {
                const optionDisabled = optionsDisabled?.includes(option.value);
                return (
                  <button
                    type="button"
                    id={option?.id}
                    key={option.id}
                    className={cn(styles.dropdownOptionsItem, {
                      [styles.dropdownOptionsItemDisabled]: optionDisabled,
                    })}
                    onClick={(e: any) => onSelectHandler(option, optionDisabled, e)}>
                    {option.value}
                  </button>
                );
              }
              return (
                <button
                  type="button"
                  id={option?.id}
                  key={index}
                  onClick={(e: any) => onSelectHandler(option, false, e)}
                  className={cn(styles.dropdownOptionsItem, {
                    [styles.dropdownOptionsItemActive]: option === defaultValue,
                    [styles.dropdownOptionsItemDisabled]: optionsDisabled?.includes(option),
                  })}>
                  {option}
                  {option === defaultValue && <CheckIcon />}
                </button>
              );
            })
          ) : (
            <div className={styles.dropdownNoResults}>No results found</div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

DropdownArray.displayName = 'DropdownArray';

export default memo(DropdownArray);
