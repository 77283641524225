import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import styles from './StatsInfo.module.scss';

const StatsInfo = () => {
  const { isLoading, data } = useAppSelector((state) => state.analytics.getAnalyticsAuthenticationRequest);

  const totalLogins = data?.data.count || 0;
  const successfulLogins = data?.data.successfulCount || 0;
  const failedLogins = data?.data.failedCount || 0;

  return (
    <div className={styles.containerRow}>
      <div className={styles.containerCol}>
        <h5 className={styles.containerTitle}>Total Logins</h5>
        <p className={styles.containerCount}>{isLoading ? <Skeleton width={40} /> : totalLogins}</p>
      </div>
      <div className={styles.containerCol}>
        <div className={styles.containerSubrow}>
          <div className={styles.containerSubcol}>
            <h6 className={styles.containerSmallTitle}>Successful Logins</h6>
            <p className={styles.containerSmallCount}>{isLoading ? <Skeleton width={15} /> : successfulLogins}</p>
          </div>
          <div className={styles.containerSubcol}>
            <h6 className={styles.containerSmallTitle}>Failed logins</h6>
            <p className={styles.containerSmallCount}>{isLoading ? <Skeleton width={15} /> : failedLogins}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsInfo;
