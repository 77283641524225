import { LoginRoute, PrivateRoute } from 'components';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ActiveDirectory,
  Customers,
  CustomersWorkspace,
  BitLockerMgmt,
  DomainMgmt,
  EmailWhoisResult,
  ForgotPassword,
  Login,
  PersonalRegistration,
  Profile,
  ResourceAssignments,
  RolesMgmt,
  SecurityMgmt,
  ShortcutMgmt,
  RememberMe,
  AzureAdLogin,
  SetupMFA,
  SetupPhone,
  Workspace,
  PasswordSuccessPage,
  EmailValidationResult,
  EmailValidation,
  TimeoutManagement,
  ForgotPasswordMgmt,
  PhoneSetupManagement,
} from 'pages';
import { useAppSelector } from 'store';
import { withCompany } from './withCompany/withCompany';
import { withCustomers } from './withCustomers/withCustomer';
import { mustSetupMfa, mustSetupPhone } from 'helpers';
import Analytics from 'pages/Analytics/Analytics';

const Routes = () => {
  const { isAuth, user } = useAppSelector((state) => state.user);

  return (
    <Switch>
      {/* Handle Redirect */}
      <Route path="/" exact render={() => <Redirect to={isAuth ? '/workspace' : '/login'} />} />

      {/* Login Routes */}
      <LoginRoute path="/login" component={Login} />

      {/* Other public Routes */}
      <LoginRoute path="/email-validation/:customerId?" exact component={EmailValidation} />
      <LoginRoute path="/email-validation/result/:token" exact component={EmailValidationResult} />
      <LoginRoute path="/reset-password/:token" exact component={ForgotPassword} />
      <LoginRoute path="/domain-verification/:publicKey" exact component={EmailWhoisResult} />
      <LoginRoute path="/personal-registration" component={PersonalRegistration} />
      <LoginRoute path="/password-success" component={PasswordSuccessPage} />

      {/* Setup MFA Route */}
      {isAuth && mustSetupMfa(user) && <LoginRoute path="/setup-mfa" component={SetupMFA} />}

      {/* Setup Phone Route */}
      {isAuth && mustSetupPhone(user) && <LoginRoute path="/setup-phone" component={SetupPhone} />}

      {/* PostLogin Company Routes */}
      <PrivateRoute path="/workspace" component={Workspace} />
      <PrivateRoute path="/company-management/resource-assignments" component={withCompany(ResourceAssignments)} />
      <PrivateRoute path="/company-management/device-management" component={withCompany(BitLockerMgmt)} />
      <PrivateRoute path="/company-management/active-directory-federation" component={withCompany(ActiveDirectory)} />
      <PrivateRoute path="/company-management/roles-management" component={withCompany(RolesMgmt)} />
      <PrivateRoute path="/company-management/security-management" exact component={withCompany(SecurityMgmt)} />
      <PrivateRoute
        path="/company-management/security-management/shortcut-management"
        component={withCompany(ShortcutMgmt)}
      />
      <PrivateRoute path="/company-management/security-management/remember-me" component={withCompany(RememberMe)} />
      <PrivateRoute
        path="/company-management/security-management/timeout-management"
        component={withCompany(TimeoutManagement)}
      />
      <PrivateRoute
        path="/company-management/security-management/azure-management"
        component={withCompany(AzureAdLogin)}
      />
      <PrivateRoute
        path="/company-management/security-management/password-management"
        component={withCompany(ForgotPasswordMgmt)}
      />
      <PrivateRoute
        path="/company-management/security-management/phone-management"
        component={withCompany(PhoneSetupManagement)}
      />
      <PrivateRoute path="/company-management/domain-management" component={withCompany(DomainMgmt)} />
      <PrivateRoute path="/company-management/analytics" component={withCompany(Analytics)} />

      {/* PostLogin Customer Routes */}
      <PrivateRoute path="/customer-management" exact component={Customers} />
      <PrivateRoute
        path="/customer-management/:domain/customer-workspace"
        component={withCustomers(CustomersWorkspace)}
      />
      <PrivateRoute
        path="/customer-management/:domain/resource-assignments"
        component={withCustomers(ResourceAssignments)}
      />
      <PrivateRoute path="/customer-management/:domain/device-management" component={withCustomers(BitLockerMgmt)} />
      <PrivateRoute path="/customer-management/:domain/roles-management" component={withCustomers(RolesMgmt)} />
      <PrivateRoute
        path="/customer-management/:domain/security-management"
        exact
        component={withCustomers(SecurityMgmt)}
      />
      <PrivateRoute
        path="/customer-management/:domain/security-management/shortcut-management"
        component={withCustomers(ShortcutMgmt)}
      />
      <PrivateRoute
        path="/customer-management/:domain/security-management/remember-me"
        component={withCustomers(RememberMe)}
      />
      <PrivateRoute
        path="/customer-management/:domain/security-management/timeout-management"
        component={withCustomers(TimeoutManagement)}
      />
      <PrivateRoute
        path="/customer-management/:domain/security-management/azure-management"
        component={withCustomers(AzureAdLogin)}
      />
      <PrivateRoute
        path="/customer-management/:domain/security-management/password-management"
        component={withCustomers(ForgotPasswordMgmt)}
      />
      <PrivateRoute
        path="/customer-management/:domain/security-management/phone-management"
        component={withCustomers(PhoneSetupManagement)}
      />
      <PrivateRoute path="/customer-management/:domain/domain-management" component={withCustomers(DomainMgmt)} />
      <PrivateRoute path="/customer-management/:domain/analytics" component={withCustomers(Analytics)} />

      {/* PostLogin Profile Routes */}
      <PrivateRoute path="/profile" component={Profile} />

      {/* Handle 404 Routes */}
      <Route path="*" render={() => <Redirect to={isAuth ? '/workspace' : '/login'} />} />
    </Switch>
  );
};

export default Routes;
