import { useAppSelector } from 'store';
import Skeleton from 'react-loading-skeleton';
import styles from './StatsInfo.module.scss';

const StatsInfo = () => {
  const { data, isLoading } = useAppSelector((state) => state.analytics.getAnalyticsComputersRequest);
  const workstationPools = data?.workstationPools ?? 0;
  const serverPools = data?.serverPools ?? 0;
  const totalComputers = workstationPools + serverPools;

  return (
    <div className={styles.info}>
      <div className={styles.infoRow}>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Computer Groups</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : totalComputers}</p>
        </div>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Workstation Pools</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : workstationPools}</p>
        </div>
        <div className={styles.infoCol}>
          <h5 className={styles.infoTitle}>Server Pools</h5>
          <p className={styles.infoCount}>{isLoading ? <Skeleton width={40} /> : serverPools}</p>
        </div>
      </div>
    </div>
  );
};

export default StatsInfo;
