import { Chart, ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { useAppSelector } from 'store';
import styles from './LicensesDomainChart.module.scss';

Chart.register(ChartDataLabels);

interface Props {
  selectedLicenseType: string;
}

const LicensesDomainChart = ({ selectedLicenseType }: Props) => {
  const { data } = useAppSelector((state) => state.analytics.getAnalyticsLicensesRequest);

  const licensesByDomain =
    data?.purchasedLicenses?.filter((domain) => domain.count > 0).map((domain) => domain.count) || [];
  const labels = data?.purchasedLicenses?.filter((domain) => domain.count > 0).map((domain) => domain.domainName) || [];
  const allocatedLabels =
    data?.allocatedLicenses
      .filter((i) => !(i.licensesAllocated === 0 && i.reportedItems === 0))
      .map((i) => i.domainName) || [];

  const licensesMax = Math.max(...licensesByDomain);

  const chartData: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: `${selectedLicenseType}`,
        data: licensesByDomain,
        backgroundColor: '#3673E8',
        borderWidth: 0,
        barPercentage: 1.2,
        maxBarThickness: 12,
        datalabels: {
          align: 'right',
          anchor: 'end',
          color: '#000000',
          font: {
            family: '"IBM Plex Sans", sans-serif',
            weight: 500,
            size: 10,
          },
        },
      },
    ],
  };

  const chartOptions: ChartOptions<'bar'> = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    layout: {
      padding: {
        right: 42,
        left: 24,
      },
    },
    scales: {
      y: {
        display: true,
        labels: labels.map((label) => (label.length > 25 ? `${label.slice(0, 25)}...` : label)),
        grid: {
          display: false,
        },
      },
      x: {
        max: licensesMax + 5,
        grid: { borderDash: [1.5, 1.5], color: 'rgba(0, 0, 0, 0.1)' },
      },
    },
    plugins: {
      legend: {
        labels: {
          padding: 20,
          font: {
            family: '"IBM Plex Sans", sans-serif',
            size: 12,
          },
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        titleFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
          weight: '600',
        },
        bodyFont: {
          family: '"IBM Plex Sans", sans-serif',
          size: 12,
        },
        usePointStyle: true,
        callbacks: {
          labelPointStyle: () => ({
            pointStyle: 'circle',
            rotation: 0,
          }),
          title: (context) => labels[context[0].dataIndex],
        },
      },
    },
  };

  const chartHeight =
    Math.max(labels.length, allocatedLabels.length) * 40 < 270
      ? 270
      : Math.max(labels.length, allocatedLabels.length) * 40;

  return (
    <div className={styles.bar}>
      <h6 className={styles.barTitle}>Purchased Licenses by Domain</h6>
      <div className={styles.barChart}>
        <Bar data={chartData} options={chartOptions} height={chartHeight} />
      </div>
    </div>
  );
};

export default LicensesDomainChart;
